var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import RadioImageButton from '@declaration/components/UI/RadioImageButton.vue';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
import { ELECTRICAL_DAMAGE_TYPES_IMAGE_CHOICES, ElectricalDamageTypesDefault, } from '@declaration/constants/electricalDamageConstants';
import { INSURER_CONFIG } from '@shared/insurer';
const electricalDamage = namespace('electricalDamage');
const contract = namespace('contract');
let ElectricalDamageTypeSection = class ElectricalDamageTypeSection extends Vue {
    get value() {
        var _a;
        const { data } = this.changeGetter(this.changeKey);
        return (_a = data.type) !== null && _a !== void 0 ? _a : null;
    }
    set value(type) {
        this.updateType({ type, changeKey: this.changeKey });
        this.untouchField({ fieldName: 'type', changeKey: this.changeKey }); // Untouch to prevent validation error on next section
        this.$emit('update');
    }
    get isOwner() {
        if (!this.contract)
            return false;
        return this.contract.housingCapacity.owner || this.contract.housingCapacity.coOwner;
    }
    get choices() {
        var _a, _b, _c, _d;
        let customValues = null;
        if (((_b = (_a = INSURER_CONFIG.declaration.pages) === null || _a === void 0 ? void 0 : _a.electricalRealEstateDamages) === null || _b === void 0 ? void 0 : _b.electricalDamageTypesImages) &&
            this.isOwner) {
            const insurerCustomValues = (_d = (_c = INSURER_CONFIG.declaration.pages) === null || _c === void 0 ? void 0 : _c.electricalRealEstateDamages) === null || _d === void 0 ? void 0 : _d.electricalDamageTypesImages;
            customValues = ELECTRICAL_DAMAGE_TYPES_IMAGE_CHOICES.filter((item) => insurerCustomValues.includes(item.value));
        }
        else {
            customValues = ELECTRICAL_DAMAGE_TYPES_IMAGE_CHOICES.filter((item) => ElectricalDamageTypesDefault.includes(item.value));
        }
        return customValues !== null && customValues !== void 0 ? customValues : ELECTRICAL_DAMAGE_TYPES_IMAGE_CHOICES;
    }
    get shouldShowNotListedButton() {
        return !this.choices.some((item) => item.value === 'autre');
    }
};
__decorate([
    electricalDamage.Getter('change')
], ElectricalDamageTypeSection.prototype, "changeGetter", void 0);
__decorate([
    electricalDamage.Action('updateType')
], ElectricalDamageTypeSection.prototype, "updateType", void 0);
__decorate([
    electricalDamage.Mutation('UNTOUCH_FIELD')
], ElectricalDamageTypeSection.prototype, "untouchField", void 0);
__decorate([
    contract.State('selectedContract')
], ElectricalDamageTypeSection.prototype, "contract", void 0);
ElectricalDamageTypeSection = __decorate([
    Component({
        components: { RadioImageButton, SecondaryButton },
        props: {
            changeKey: { type: [Number, String], default: null },
            errors: { type: Array, default: () => [] },
        },
    })
], ElectricalDamageTypeSection);
export default ElectricalDamageTypeSection;
