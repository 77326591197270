var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { layoutMiddlewares } from '@declaration/middleware/middlewares';
import SecondaryButton from '@declaration/components/UI/SecondaryButton.vue';
import Maintenance from '@declaration/components/declaration/pages/maintenance.vue';
import { ClaimLanguage } from '@shared/types/api/user';
import { DeclarationPage } from '@declaration/pages';
const claim = namespace('claim');
const authentication = namespace('authentication');
const pages = namespace('pages');
let Index = class Index extends Vue {
    constructor() {
        super(...arguments);
        this.isLoading = false;
        this.declarationImage = require('@declaration/assets/images/appointment/leakageDetection.svg');
        this.trackImage = require('@declaration/assets/images/documents/checklist.svg');
        this.DeclarationPage = DeclarationPage;
    }
    get canDeclare() {
        return !this.isAuthenticatedWithMagicLink;
    }
    async declare() {
        this.isLoading = true;
        try {
            this.resetStore();
            await this.createNewClaim();
            this.$goToLocalePath('/declaration');
        }
        finally {
            this.isLoading = false;
        }
    }
    track() {
        this.$goToLocalePath('/mes-declarations');
    }
    mounted() {
        this.resetStore();
    }
    async logout() {
        this.isLoading = true;
        this.$i18n.locale = ClaimLanguage.French;
        await this.$store.dispatch('authentication/logout').catch(() => {
            this.isLoading = false;
        });
        this.$goToLocalePath('/login');
    }
};
__decorate([
    claim.Action('resetStore')
], Index.prototype, "resetStore", void 0);
__decorate([
    pages.Getter('currentPage')
], Index.prototype, "currentPage", void 0);
__decorate([
    claim.Action('createNewClaim')
], Index.prototype, "createNewClaim", void 0);
__decorate([
    authentication.Getter('isAuthenticatedWithMagicLink')
], Index.prototype, "isAuthenticatedWithMagicLink", void 0);
Index = __decorate([
    Component({
        components: { SecondaryButton, Maintenance },
        middleware: layoutMiddlewares('authenticated', 'forbidMagicLinkAuth'),
        meta: {
            goBack: null,
        },
    })
], Index);
export default Index;
